html,
body {
  height: 100%;
  background-color: #eeeeee !important;
}

.MuiPickersStaticWrapper-staticWrapperRoot,
.MuiPickersBasePicker-pickerView {
  min-width: 100% !important;
}

.k-animation-container {
  z-index: 9999 !important; // set the desired value based on the application requirements
}

.k-upload > .k-actions {
  display: none;
}
